<template>
  <div>
    <!--begin::customer-->
    <div>
       <h3 class="card-label">
            {{ $t('sales_shipping.sales_shipping') }}
            <span class="text-muted pt-2 font-size-sm d-block"></span>
          </h3>
      <div class="row">
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('sales_shipping.name') }}<span class="text-danger">*</span></label>
                <input type="text" v-model="data.name" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.name[0] }}
                            </span>
              </div>
            </div>
          </div>
          <div class="card card-custom mt-5">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('sales_shipping.tax') }}</label>
                <!-- <select name="" id="tex_id" v-model="data.tax_id" class="custom-select" :class="validation && validation.tax_id ? 'is-invalid' : ''">
                    <option v-for="row in taxes" :value="row.id" :key="row.id">{{ row.name }}</option>
                </select> -->
                <div class="input-group">
                  <multiselect v-model="tax"
                               :placeholder="$t('sales_shipping.tax')"
                               label="name"
                               track-by="id"
                               :options="taxes"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/settings/taxes/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
                <span v-if="validation && validation.tax_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.tax_id[0] }}
                            </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('sales_shipping.fees') }}</label>
                <input type="number" v-model="data.fees" class="form-control" :class="validation && validation.fees ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.fees" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.fees[0] }}
                                    </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('sales_shipping.currency') }}</label>
                <div class="input-group">
                  <select name="" id="currency_id" v-model="data.currency_id" class="custom-select" :class="validation && validation.currency_id ? 'is-invalid' : ''">
                    <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                  </select>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/settings/currencies" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
                <span v-if="validation && validation.currency_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.currency_id[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('exchange_rate') }}</label>
                <input type="number" v-model="data.exchange_rate" step="0.01" min="0" class="form-control" :class="validation && validation.exchange_rate ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.exchange_rate" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.exchange_rate[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('sales_shipping.order') }}</label>
                <input type="number" v-model="data.order" class="form-control" :class="validation && validation.order ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.order" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.order[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <b-form-checkbox size="lg" v-model="data.status" name="check-button" switch>{{ $t('status') }}</b-form-checkbox>
              </div>
            </div>
          </div>
          <div class="card card-custom mt-5">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('sales_shipping.notes') }}</label>
                <textarea name="" id="notes" v-model="data.notes" class="form-control" :class="validation && validation.notes ? 'is-invalid' : ''"></textarea>
                <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.notes[0] }}
                            </span>
              </div>
            </div>
          </div>
        </div>

      </div>

        <div class="pr-0 pl-0">
          <div class="row">
            <div class="col-lg-6 mt-5">
              <button type="reset" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
              <button type="reset" class="btn btn-secondary">{{ $t('cancel') }}</button>
            </div>
          </div>
      </div>
    </div>
    <!--end::customer-->
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import {mapGetters, mapState} from "vuex";

export default {
  name: "form-sales-shipping",
  data() {
    return {
      mainRoute: 'sales/sales_shippings',
      mainRouteDependency: 'base/dependency',

      data: {
        name: null,
        fees: null,
        tax_id: null,
        currency_id: null,
        order: null,
        notes: null,
        status: true,
        exchange_rate: null
      },
      idEditing: this.$route.params.id,
      isEditing: false,

      currencies: [],
      taxes: [],
      tax: null,
      validation: null,
    };
  },
  computed: {
    ...mapState({user_personal_info: state => state.profile.user_personal_info}),
    ...mapGetters(["currentUserPersonalInfo"]),
  },
  watch: {

    tax: function (val) {
      if (val) {
        this.data.tax_id = val.id;
      }
    },
    "data.currency_id": function (val) {
      if (val) {
        this.getExchangeRate(val);
      } else {
        this.data.exchange_rate = null;
      }
    },
  },
  methods: {
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      ApiService.post(`${this.mainRoute}`, {
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);
            this.$router.push({name: 'sales_shipping.index'});
          })
          .catch((error) => {
            this.validation = error.response ? error.response.data.errors : null;
            this.$errorAlert(error);
          });
    },


    update() {
      ApiService.put(`${this.mainRoute}/${this.$route.params.id}`, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$router.push({name: 'sales_shipping.index'});
        this.$successAlert(response.data.message);
      })
          .catch((error) => {
            this.validation = error.response ? error.response.data.errors : null;
            this.$errorAlert(error);
          });
    },

    async getData() {
      ApiService.get(`${this.mainRoute}/${this.$route.params.id}`).then((response) => {
        this.isEditing = true;
        this.data.name = response.data.data.name;
        this.data.fees = response.data.data.fees;
        this.data.tax_id = response.data.data.tax_id;
        this.tax = response.data.data.tax;
        // tax
        // exchange_rate

        this.data.exchange_rate = response.data.data.exchange_rate;
        this.data.currency_id = response.data.data.currency_id;
        this.data.order = response.data.data.order;
        this.data.notes = response.data.data.notes;
        this.data.status = response.data.data.status;
      });
    },

    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    getTaxes() {
      ApiService.get(this.mainRouteDependency + "/taxes?type=4").then((response) => {
        this.taxes = response.data.data;
      });
    },
    loadOptions() {
    },


    getExchangeRate(id) {
      ApiService.get(`${this.mainRouteDependency}/exchange_rate/` + id).then((response) => {
        this.data.exchange_rate = response.data.data.initial_val;
      });
    },
    defaultDataForUser() {
      ApiService.get(this.mainRouteDependency + "/default_data_for_user").then((response) => {
        this.data.currency_id = response.data.data.currency_id;
        this.data.tax_id = response.data.data.tax_id;
        this.tax = response.data.data.tax;
      });
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.sales_shipping_management"), route: '/sales/sales_shipping'}, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);


    this.getCurrencies();
    this.getTaxes();

    if (this.idEditing) {
      this.getData();
    } else {
      this.defaultDataForUser();
    }

  },
};
</script>